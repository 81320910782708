import React from "react";
import {Route, Routes} from "react-router-dom";
import H from './Home';
import T from "./Terapias";
import P from "./Perfil";
import C from "./Contacto";
import L from "./Libro"

export default function AnimateRoutes(){
    return(
            <Routes >
                <Route exact path="/" element={<H />} />
                <Route path="/terapias" element={<T/>}/>
                <Route path="/perfil" element={<P/>}/>
                <Route path="/contacto" element={<C/>}/>
                <Route path="/libro" element={<L/>}/>
            </Routes>
    )
}