
import React, {useState} from 'react'; 
import {Form, Input, TextArea, Button} from 'semantic-ui-react';
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import "./Contacto.css"
import NavBar from './NavBar';
import {LiaHandPointRight} from 'react-icons/lia'

export default function Contacto(){
    const [mensaje, setMensaje] = useState({
        from_email: '', 
        from_name: '',
        message: ''
    });
    
    const handleChange = (e) => {
        setMensaje({
            ...mensaje, 
            [e.target.name]: e.target.value
        })
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, mensaje, process.env.REACT_APP_PUBLIC_KEY)
          .then((result) => {
            Swal.fire({
              icon: "success",
              title: "Mensaje enviado correctamente"
            })
          }, (error) => {
            Swal.fire({
              icon: "error",
              title: "Ooops, algo salio mal",
              text: error.text,
            })
          });
        e.target.reset()
        setMensaje({
          from_email: '', 
          from_name: '',
          message: ''
        })
      };

    return(
        <div>a
            <div className="bg-img-form"></div>
            <NavBar/>
            <div className="Form-Contain">
                <div className='Form-Text'>Puedes contactarme a través de mis redes sociales o enviándome un mensaje mediante el formulario. Ingresa tu correo electrónico, nombre y el mensaje que desees enviarme.</div>
                <div className="Redes">
                    <a href='https://www.instagram.com/claudinadasilveria/' className="Redes-hover"><LiaHandPointRight className='Hands'/>Instagram</a>
                    <a href='https://www.facebook.com/claudina.dasilveira' className="Redes-hover"><LiaHandPointRight className='Hands'/>FaceBook</a>
                </div>
                <div className="Email">
                    <Form onSubmit={handleOnSubmit}>
                    <Form.Field
                        id="form-input-control-email"
                        control={Input}
                        label="Email"
                        type='Email'
                        name="from_email"
                        placeholder="Email…"
                        required
                        icon="mail"
                        iconPosition="left"
                        value={mensaje.from_email}
                        onChange={e =>handleChange(e)}
                    />
                    <Form.Field
                        id="form-input-control-last-name"
                        control={Input}
                        label="Nombre"
                        name="from_name"
                        placeholder="Nombre…"
                        required
                        icon="user circle"
                        iconPosition="left"
                        value={mensaje.from_name}
                        onChange={e =>handleChange(e)}
                    />
                    <Form.Field
                        id="form-textarea-control-opinion"
                        control={TextArea}
                        label="Mensaje"
                        name="message"
                        placeholder="Mensaje…"
                        required
                        value={mensaje.message}
                        onChange={e =>handleChange(e)}
                    />
                    <Button type="submit" color="green">Enviar</Button>
                    </Form>
                </div>
            </div>
      </div>
    )
}