import React, {useState, useRef, useEffect} from "react";
import "./NavBar.css"
import {PiTreeLight} from 'react-icons/pi'
import {GiHamburgerMenu, GiHouse} from 'react-icons/gi'
import {ImCross} from 'react-icons/im'
import {HiOutlineArrowUturnLeft} from 'react-icons/hi2'
import { Link} from "react-router-dom";
import { gsap } from "gsap";
import logo from "../img/Logo.jpg"

export default function NavBar(){
    const [classMenu, setClassMenu] = useState("Menu-Disable")
    const [classNavBar, setClassNavBar] = useState("Nav-Bar")
    const [activated, setActivated] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const currentPath = window.location.pathname;
    let menu = useRef(null)
    let navBar = useRef(null)

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    function Activated(){
        if(!activated){
            setClassMenu("Menu")
            gsap.to(menu, {opacity: 1, x: 300, duration: 0.5});
            gsap.to(navBar, {opacity: 0, y: -100, duration: 0.5})
            setTimeout(() => {
                setActivated(true)
                setClassNavBar("Nav-Bar-Disable")
              }, 500);
        }
        else{
            gsap.to(menu, {opacity: 0, x: -300, duration: 0.5});
            gsap.to(navBar, {opacity: 1, y: 0, duration: 0.5})
            setClassNavBar("Nav-Bar")
            setTimeout(() => {
                setClassMenu("Menu-Disable")
                setActivated(false)
              }, 500);
        }
    }

    return (
        <div>
            <div className={classNavBar} ref={el => navBar = el}>
                {
                    windowWidth <= 650 ? (
                        <div className="Nav-Phone">
                            <div onClick={e => Activated(e)}><GiHamburgerMenu className="Burger"/></div>
                            {
                            currentPath !== "/" ? <Link to={"/"} ><button className="ButtonHome"><HiOutlineArrowUturnLeft className="Home"/> Inicio</button></Link> : null
                            }
                        </div>
                    ) : 
                    <div className="Nav-Button" >
                        <div>
                            <img src={logo} alt="Sanar" className="LogoNav"/>
                        </div>
                        {
                            currentPath !== "/" ? <Link to={"/"} ><button className="Button"><GiHouse className="Home"/> Inicio</button></Link> : null
                        }
                        {
                            currentPath !== "/perfil" ?  <Link to={"/perfil"}> <button className="Button"><PiTreeLight className="Tree"/> Sanar <PiTreeLight className="Tree"/></button> </Link>
                            : null
                        }
                        {
                            currentPath !== "/terapias" ? <Link to={"/terapias"}> <button className="Button"><PiTreeLight className="Tree"/> Terapias <PiTreeLight className="Tree"/></button> </Link> 
                            : null
                        }
                        {
                            currentPath !== "/contacto" ? <Link to={"/contacto"}> <button className="Button"> <PiTreeLight className="Tree"/> Contacto  <PiTreeLight className="Tree"/></button> </Link> 
                            : null
                        }
                                                {
                            currentPath !== "/libro" ? <Link to={"/libro"}> <button className="Button"> <PiTreeLight className="Tree"/> Libro <PiTreeLight className="Tree"/></button> </Link> 
                            : null
                        }
                    </div>
                }
            </div>
            {windowWidth <= 650 ? (
                <div ref={el => menu = el} className={classMenu}>
                <div className="Cross" onClick={e => Activated(e)}><ImCross/></div>
                        {
                            currentPath !== "/perfil" ?  <Link to={"/perfil"}> <button className="Button"><PiTreeLight className="Tree"/> Sanar</button> </Link>
                            : null
                        }
                        {
                            currentPath !== "/terapias" ? <Link to={"/terapias"}> <button className="Button"><PiTreeLight className="Tree"/> Terapias </button> </Link> 
                            : null
                        }
                        {
                            currentPath !== "/contacto" ? <Link to={"/contacto"}> <button className="Button"> <PiTreeLight className="Tree"/> Contacto </button> </Link> 
                            : null
                        }     
                        {
                            currentPath !== "/libro" ? <Link to={"/libro"}> <button className="Button"> <PiTreeLight className="Tree"/> Libro </button> </Link> 
                            : null
                        }
                </div>) 
                : null  
            }
        </div>
    )
}