import AnimateRoutes from "./components/AnimatedRoutes"

function App() {

  return(
      <>
        <AnimateRoutes/>
      </>
  );
}

export default App;
