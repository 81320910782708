import React from "react";
import NavBar from "./NavBar";
import "./Perfil.css"
import ImgPerfil from '../img/Perfil.png'

export default function Perfil(){
    return(
        <div>a
            <div className="bg-img"></div>
            <NavBar/>
            <img src={ImgPerfil} alt="Sanar" className="ImgPerfil"/>
            <div className="Perfil">
                <h1>CLAUDINA DA SILVEIRA</h1>
                <p>Soy quien conociendo las sombras, atravesando tormentas y viviendo el dolor más profundo nunca dejó de buscar su propia luz y una vez que la encontré decidí acompañar a todos aquellos que aún la están buscando.</p>
                <p>Soy co-autora del libro SANAR, Reikista, tengo una Maestría en Angelología, Mujer Medicina portadora del Rito del útero, Sanación del linaje paterno, Niño interior y búsqueda del Animal de poder. Biodescodificadora y Consteladora.</p>
                <p>Todo lo que vas a encontrar en estas terapias complementarias ya fue experimentado por mí y por muchos consultantes que van llegando por diferentes caminos.</p>
                <p>Puedo asegurarte sin temor a equivocarme que TÚ ERES TU PROPIO SANADOR, BUSCA LA LUZ QUE HAY EN TÍ, BUSCA LAS HERRAMIENTAS, COMIENZA A VIVIR TU VIDA Y SÉ DUEÑO DE ELLA TOMANDO LAS DESICIONES NECESARIAS.</p>
            </div>
        </div>
    )
}