import React from "react";
import NavBar from "./NavBar";

export default function Libro(){
    return(
        <div>Libro
            <NavBar/>
            <div className="bg-img"></div>
            <h1 className="Titulo">Sanar</h1>
            <p className="Text">
            Encontrar sentido a la vida no es tarea sencilla.
            Desde el momento que elegiste comprar este libro,
            tomaste la decisión de realizar un cambio para liberar
            y sanar patrones heredados.
            Buscar ayuda, entre otras cosas, tiene que ver con aprender
            a utilizar los recursos que tenemos a nuestro alrededor.
            Cuando decimos recursos, nos referimos a la energía que
            </p>
            <p className="Text">
            nos rodea y nos constituye, tan poderosa e importante para
            la existencia. Es por eso que uno de los objetivos de este
            libro es trabajar con la energía chamánica, recuperando
            rituales ancestrales, trabajando y canalizando la energía
            universal que nos rodea, abordando los cuatro elementos:
            
            
            tierra, agua, aire y fuego, indispensables para la vida.
            </p>
            <p className="Text">
            No es tarea fácil estar dispuestos a cambiar: implica mucha
            fuerza, dedicación, compromiso y entrega.
            De allí la importancia de trabajar el amor propio
            en el primer capítulo, para fortalecer el alma, la esencia
            y el ser maravilloso que somos.
            Despertar implica recorrer un largo camino donde
            se movilizan muchas cuestiones del ser:
            emociones, sentimientos, pensamientos, recuerdos.
            Es un camino que tiene avances, retrocesos, idas y vueltas,
            altos y bajos, pero que ES POSIBLE.

            No pongas barreras ni límites; permitete ser, sentir
            y poner a prueba todo lo que vas a encontrar
            y descubrir en estas páginas.
            </p>
            <h4 className="SubTitulo">
                RECUERDA QUE, CADA VEZ QUE LLORAS,
            </h4>
            <h4 className="SubTitulo">
                RÍES O ESCRIBES
                LO QUE SIENTES, ELEVAS TU VIBRACIÓN.
            </h4>

        </div>
    )
}