import React, {useState} from "react";
import NavBar from "./NavBar";
import "./Terapias.css"
import {HiOutlineArrowSmallLeft} from 'react-icons/hi2'

export default function Terapias(){
    const [Terapia, setTerapia] = useState("Inicio")

    function changeTerapia(terapia) {
        setTerapia(terapia)
    }

    return(
        <div>.
            <div className="bg-img"></div>
            <NavBar/>
            {
                Terapia === "Inicio" ? 
                <div>
                    <h1 className="Titulo">Terapias</h1>
                    <div className="Grid">
                        <button className="Box" onClick={() => changeTerapia("RitoDelUtero")}>RITO DEL ÚTERO</button>
                        <button className="Box" onClick={() => changeTerapia("LinajePaterno")}>LINAJE PATERNO</button>
                        <button className="Box" onClick={() => changeTerapia("SanacionDelNino")}>SANACIÓN DEL NIÑO INTERIOR</button>
                        <button className="Box" onClick={() => changeTerapia("Biodecodificacion")}>BIODESCODIFICACIÓN</button>
                        <button className="Box" onClick={() => changeTerapia("ConstelacionesFamiliares")}>CONSTELACIONES FAMILIARES </button>
                    </div> 
                </div> 
                : null
            }
            {
                Terapia === "RitoDelUtero" ?
                <div>
                    <div className="navTerapias">
                        <button className="ButtonBack"  onClick={() => changeTerapia("Inicio")}><HiOutlineArrowSmallLeft className="Home"/> Atras</button>
                    </div>
                    <h1 className="Titulo">Rito Del Útero</h1>
                    <h3 className="Titulo">QUÉ ES LA SANACIÓN DEL ÚTERO?</h3>
                    <p className="Text">
                        La Sanación del Útero es una experiencia de “apoyo”, diseñada para despertar suavemente, sanar y equilibrar todos los aspectos de nuestra feminidad. La mujer crece en bienestar, confianza, autoestima y conexión con el propio poder, y se puede dar tan a menudo como sea necesario.
                        La Sanación del Útero también puede ser una forma suave para ayudar a una mujer para prepararse para las energías de la Bendición, y también puede ayudar a la integración de la energía y en cualquier efecto de ajuste producido por la energía de la Bendición del Útero.
                        Beneficios de la Sanación del Útero:
                        <ul>
                            <li>Ayuda al ciclo hormonal y abre la conciencia espiritual receptora.</li>
                            <li>Sana las cuestiones de amor, del cuidado, del dar y recibir.</li>
                            <li>Trabaja sobre los problemas físicos y los patrones emocionales.</li>
                        </ul>
                        La energía trabaja en los cuatro arquetipos femeninos del ciclo menstrual y nuestra aceptación de sus energías y su presencia, tanto en mujeres cíclicas, como en mujeres completas (mujeres que han superado la menopausia).
                        INFORMACIÓN GENERAL PARA TRABAJAR LUEGO DE RECIBIDO EL RITUAL.
                    </p>
                    <h4 className="SubTitulo">Fase de la Luna nueva (arquetipo de “La Bruja”)</h4>
                    <p className="Text">
                        Nuestro ciclo comienza el primer día del sangrado, y con esto entramos en la primera fase, simbolizado en la parte inferior de la imagen por la luna nueva. Esta etapa que suele durar de 3 a 5 dias, es un momento de “muerte” y de introspección, aquí se cierra un ciclo y comienza otro. ¿Se imaginan como cambiaría nuestra visión de esos días si pudiéramos entenderlos como un momento de liberación increíble, donde dejamos atrás todo lo viejo, para darle la bienvenida a lo nuevo? Es en esta fase donde la mujer puede contactar con su parte oscura, fría y receptiva, que muchas negamos. Tenemos el don de morir y renacer cada mes, y no solemos aprovecharlo.
                        Sería muy apropiado descansar mucho en esta fase, dormir y soñar y meditar. Sin embargo, nuestra sociedad nos exige que vivamos estos días de la misma manera que los otros, lo que provocara mucho cansancio e irritación (¿les suena?).
                    </p>
                    <h4 className="SubTitulo">Fase de la luna creciente (arquetipo de la “La Virgen”)</h4>
                    <p className="Text">
                        La siguiente etapa es la fase del renacer luego de la muerte, aquí el cuerpo de la mujer comienza a prepararse para gestar un nuevo óvulo. Es una etapa dinámica y radiante, ya que este es el momento en el que la mujer se libera de su ciclo procreativo y se centra solo en su propio ser. Solemos sentir confianza en nosotras y necesidad de sociabilizar. La concentración y la ambición se vuelven más fuertes, por lo que es en esta etapa del ciclo donde la mujer puede centrarse más en su trabajo. La sexualidad en esta etapa suele ser fresca y juguetona. Algunas se estancan en esta etapa e intentan (de manera antinatural) vivir de forma lineal (o sea, constante) bajo esta energía. Lo que da como resultado una mujer hiper sociable, hiper trabajadora, desconectada de su lado más maternal y empático, y también de su lado oscuro, del que huye constantemente.
                    </p>
                    <h4 className="SubTitulo">Fase de la luna llena (arquetipo de “La Madre”)</h4>
                    <p className="Text">
                        Esta fase comienza cerca de la ovulación, por lo que su energía estará más en consonancia con la abnegación y el cuidado de otros, ambos relacionados con la maternidad. Suele haber menos interés por sí misma y, en cambio, muestra más necesidad de asumir responsabilidades y de “alimentar” proyectos e ideas que ya existen. La sexualidad de la mujer en esta etapa suele manifestarse como una experiencia de amor profundo y el deseo de compartir. Si presta atención, puede que note también que en esta etapa la gente busca mas su ayuda y su apoyo.
                        Una mujer que se estanca en esta fase de su ciclo tendrá muchísimos problemas para decir que no, y fácilmente caerá en la trampa de convertirse en un “felpudo humano” para que no perder el amor de sus seres queridos.
                    </p>
                    <h4 className="SubTitulo">Fase de la luna menguante (arquetipo de "La Hechicera")</h4>
                    <p className="Text">
                        En esta etapa, el óvulo ya ha sido liberado y no ha sido fecundado. Comienza entonces en las mujeres una necesidad de profundizar en el lado más interno de su ser. Esta es una etapa de gran creatividad, donde se puede aprovechar la energía que la mujer acumula cada mes para crear una nueva vida en su vientre, para hacer arte o explorar otros tipos de expresión creativa. Suele disminuir su capacidad de concentración y su tolerancia ante lo mundano, pero crece su intuición y su capacidad para soñar.
                        Esta etapa es la que más comúnmente suele dar “problemas”, ya que aquí la mujer se enfrenta con la idea inconsciente de no haber sido fertilizada y con la idea consciente de que se avecina el momento del sangrado (el momento “sucio” del ciclo). Por lo tanto, no es de extrañar que el síndrome premenstrual sea algo tan típico hoy en día. La resistencia que se vive dentro de cada mujer en esta fase es muy grande, y suele traer alteraciones como hinchazón, dolores, irritabilidad, emocionalidad desbordada, etc.
                        Y entonces el ciclo vuelve a empezar, solo que esta vez ya nos conocemos un poco mejor, y la próxima un poco más… hasta que nos convertimos en expertas de nosotras mismas. 
                    </p>
                </div>: null
            }
            {
                Terapia === "LinajePaterno" ?
                <div>
                    <div className="navTerapias">
                        <button className="ButtonBack"  onClick={() => changeTerapia("Inicio")}><HiOutlineArrowSmallLeft className="Home"/> Atras</button>
                    </div>
                    <h1 className="Titulo">Linaje Paterno</h1>
                    <h3 className="Titulo">¿Por qué sanar el linaje paterno?</h3>
                    <p className="Text">
                        Al sanar el Linaje Paterno el vínculo Padre e Hijo, se restaura y de nuevo circula la fuerza, la energía, la potencia y por lo tanto el amor y la vida.
                        Es reconciliarte, honrar a cada uno de tus ancestros, para poder sanar tu hombre interior y dejar que la mujer que eres se vuelva amorosa y plena. Y si eres hombre a reconectar con lo sagrado masculino que vive en ti.
                        Una de las principales tareas que tenemos en esta vida es la de identificar y liberar los patrones de conductas y creencias que hemos heredado de nuestros ancestros. Nuestro linaje materno y paterno influyen notoriamente y muchas veces nos condicionan y hasta pueden distorsionar nuestra perspectiva de la vida.
                        No se trata solo de ideas que nos han sido transmitidas, sino de creencias, que sin darnos cuenta hemos adoptado. En muchos casos ni siquiera nos damos cuenta de que las tenemos, solo que a la hora de actuar, muy probablemente, serán ellas y no nuestras ideas las que condicionen nuestro accionar.
                        De acá la importancia de reconocer nuestros errores, ver si repetimos circunstancias, etcétera, y adoptar la terapia necesaria para corregir el desequilibrio.
                        Una vez que nos hemos emancipado de esta forma de expresión, atraemos las situaciones, oportunidades, parejas, trabajo que más nos convienen. Todo en nuestra vida fluye de manera más saludable y fácil.
                        Una vez que nos hemos emancipado de esta forma de expresión, atraemos las situaciones, oportunidades, parejas, trabajo que más nos convienen. Todo en nuestra vida fluye de manera más saludable y fácil.
                        Por ejemplo, la figura del padre representa la seguridad, el mundo de afuera, la contención, el modelo a seguir para tener identidad, nos enseña a proveer y como lograrlo. Un padre presente logra una sana separación entre la madre y su hijo, sin embargo, de no cubrirse este rol, se puede dar una simbiosis entre madre-hijo que dure de por vida. Esto impide que ambos se desarrollen independientemente.
                        Para un hijo varón representa nada menos que su identidad como varón. Una hija necesita ser amada por su padre y verlo como líder, admirarlo y sentirse segura con su presencia para poder elegir el hombre adecuado que la acompañe. Si el padre estuvo ausente, esa mujer desconoce lo que es ser amada por un hombre.
                        Para ambos, hijas e hijos, la buena relación con el padre influye de manera directa en la autoestima.
                        (Texto de Viviana Bergmann)
                        Es indispensable para ti integrar y sanar memorias masculinas cuando estás en conflicto con los siguientes aspectos en tu vida :
                        <ul>
                            <li>Si no hayas el valor para seguir en esta vida.</li>
                            <li>Si te da miedo afrontar la vida.</li>
                            <li>Si necesitas dar pasos para un nuevo proyecto.</li>
                            <li>Si eres promiscuo/a</li>
                            <li>Si eres adicto al conflicto y esto te lleva a relaciones insanas llenas de abuso o maltrato.</li>
                            <li>Si tienes las ganas pero , no el conocimiento.</li>
                            <li>Si eres hombre y te excluyen en cosas importantes como la crianza de tu hijo.</li>
                            <li>Si eres mujer y no tienes fuerza para criar a tus hijos.</li>
                            <li>Si eres mujer y no tienes relaciones saludables.</li>
                            <li>Si eres hombre y eres objeto de maltrato femenino.</li>
                            <li>Si todo lo que emprendes fracasa.</li>
                            <li>Si tienes padre o hermano que haya muerto siendo tu pequeño o grande.</li>
                            <li>
                                Si tu padre que te abandono desde muy pequeño/a
                                Si tu padre que jamás cumplió su papel de guía , protector , proveedor de todo aquello que necesitabas , alimento, fuerza , valentía, sabiduría para enfrentar la vida .
                            </li>
                            <li>Si has tenido Padre, Hijo, Hermano, Tio , Abuelo, Primo abusador de su fuerza y hace gala de crueldad física o emocional sexual hacia su madre o tu madre , sus hijos o sobrinos. O cualquier ser indefenso.</li>
                            <li>Si has tenido padre hijos abuelo hermanos con vicios.</li>
                            <li>Si has padre o cualquier miembro masculino hijo, hermano, que sea promiscuo falto de compromiso sobre todo consigo mismo.</li>
                            <li>
                                Si has padre, o cualquier miembro masculino que no trabaje y odie el trabajo .
                                Es la relación con el padre la que determina cuán exitosas o no serán en sus relaciones con los hombres.
                                Un padre es muy importante en la vida de todas las mujeres. La energía del padre genera fuerza, confianza, manejo de límites, poder personal… y es un punto clave, pues determina cómo tener una relación de amor con un hombre.
                            </li>
                        </ul>
                        Cómo puede un hijo construir una masculinidad sana, construirse como hombre, cuando su padre ha sido peligroso o ha estado ausente? No puede. Se construye desde lo que «no quiere», porque le falta una parte, su linaje masculino.
                        De este modo, solo sanando el linaje masculino se puede construir una masculinidad sana, armoniosa y completa. Cuando esto tiene lugar, el vínculo padre-hijo se restaura y de nuevo circula la fuerza, la energía, la potencia y, por lo tanto, el amor y la vida.
                    </p>
                </div>: null
            }
            {
                Terapia === "SanacionDelNino" ?
                <div>
                    <div className="navTerapias">
                        <button className="ButtonBack"  onClick={() => changeTerapia("Inicio")}><HiOutlineArrowSmallLeft className="Home"/> Atras</button>
                    </div>
                    <h1 className="Titulo">Sanación Del Niño Interior</h1>
                    <p className="Text">
                        El Niño Interior es el núcleo de nuestro ser emocional que comenzó a gestarse en la infancia, en los primeros siete u ocho años de vida. Ir a su rescate es cambiar nuestra programación emocional, es sanar las heridas que se generaron en nuestra niñez y que se han quedado allí hasta el día de hoy, ocasionándonos problemas en nuestro mundo de adulto y que no relacionamos con nuestra infancia. Por ejemplo: “Los problemas que tengo con mi pareja es porque él (o ella) es... necio, ignorante, desconsiderado, celoso, impulsivo, etcétera. Y porque yo soy..”. Pero en realidad, esa es la forma, lo que se ve a simple vista. El fondo, en ambos, está en sus heridas emocionales de infancia. Lo mismo pasa con los otros problemas inter o intra personales: si no puedo conservar ningún empleo, si estoy siempre enfermo y me accidento constantemente, si no me hablo con mis hermanos, si odio mi trabajo, si tengo problemas con mis hijos, con mis compañeros de trabajo, si vivo en la depresión o en el resentimiento, etcétera. La mayor parte de los problemas que tenemos como adultos están fuertemente conectados con las heridas generadas durante los primeros años de nuestra vida. Uno piensa que ante las dificultades, ese señor de cuarenta años está reaccionando, esa abogada con maestría... sin embargo, quien está llevando el control de la tormenta emocional es ese niño lastimado que habita dentro del cuerpo del adulto. Cada individuo puede avanzar enormemente en su evolución interna si se ocupa de sanar las heridas emocionales que tuvo en su infancia, ya que verdaderamente dentro de cada persona existe aún aquella criatura vulnerable que se fue y que se supone quedó en el pasado. Descubrirla, recuperarla y nutrirla en amor es sin duda una de las mejo res cosas que podemos hacer por nosotros mismos; a partir de este punto, el manejo emocional de nuestra vida puede transformarse enormemente para bien
                    </p>
                    <p className="Text">
                        ¿Cómo parar esta locura? Solamente enfrentando lo que dolió y echando fuera toda esa “basura emocional”, para que podamos ver la vida a todo color, disfrutar con agradecimiento de lo hermoso, aprender de los episodios difíciles y amarnos más a nosotros mismos y por lo tanto al prójimo. Nadie puede ser un adulto feliz y completo sin traer al Niño Interior a la superficie, reconociéndolo, recuperándolo y sanándolo, porque: el Niño Interior ES nuestro ser emocional. 
                        ( Texto de Margarita Blanco)
                        LOS SIETE SINTOMAS DEL NIÑO HERIDO
                        <ul>
                            <li>¿Reaccionas de forma exagerada, pierdes los estribos o te sumes en la tristeza ante situaciones sin importancia, ahogándote en un vaso de agua, sin conseguir hallar la salida o una solución?</li>
                            <li>¿Tropiezas una y otra vez con la misma piedra, repitiendo conductas que no te convienen o interesan, cometiendo errores que creías ya superados o recayendo en ideas obsesivas?</li>
                            <li>¿Te gustaría comunicarte mejor con tus padres, hijos y hermanos y que hubiera más armonía en tu familia, pero vuestras relaciones son explosivas y parecen hablar en distintos idiomas?</li>
                            <li>¿Si tu jefe o un compañero de trabajo te piden que corrijas o cambies la forma de realizar una tarea o te hacen una crítica sientes que es una ofensa, te ofuscas y pones a la defensiva? </li>
                            <li>¿Tienes dificultades para encontrar pareja, pero cuando mantienes una relación amorosa, no dura mucho porque surgen los conflictos, entra en crisis, se rompe y vuelves a la soledad?</li>
                            <li>¿Descuidas a menudo tu salud física, comiendo de forma desequilibrada, no descansando lo suficiente, llevando una vida sedentaria o siguiendo costumbres que perjudican tu organismo?</li>
                            <li>¿Quisieras ganar más dinero o mejorar tu situación económica, pero sientes que no te lo mereces, no perseveras en tus esfuerzos o los auto-saboteas y tienes resistencias a lograr la prosperidad? </li>
                        </ul>
                        Si tus respuestas a algunos de estos interrogantes son afirmativas, ello es señal de que necesitas sanar tu Niño Interior, esa parte que simboliza nuestro ser emocional, que creció en un entorno familiar determinado, y que, a muy temprana edad, empezó a ser condicionado a través de la culpa, la crítica, el miedo, el rechazo…
                    </p>
                </div>: null
            }
            {
                Terapia === "Biodecodificacion" ?
                <div>
                    <div className="navTerapias">
                        <button className="ButtonBack"  onClick={() => changeTerapia("Inicio")}><HiOutlineArrowSmallLeft className="Home"/> Atras</button>
                    </div>
                    <h1 className="Titulo">Biodescodificación</h1>
                    <p className="Text">
                        La Biodescodificación es una propuesta de la medicina alternativa que intenta encontrar el origen metafísico de las enfermedades, o su significado emocional, para buscar a partir de allí la forma de sanar. La Biodescodificación no reemplaza a otro tipo de terapias o tratamientos, sino más bien que los complementa. Busca la activación de ciertos códigos en las células, para que recuperen un comportamiento armónico y saludable, de forma que pueda paliarse de forma eficaz el trastorno o la enfermedad y el paciente recupere la calidad de vida.
                    </p>
                    <p className="Text">
                        Como indica su nombre, se trata de "descodificar" las emociones que aquejan a la persona para liberarlas y conseguir la curación. Según la Medicina Tradicional China hay emociones que pueden afectar ciertos órganos o funciones. Por ejemplo, la tristeza disminuye el sistema inmunológico y da predisposición a enfermedades respiratorias y digestivas. La ira y la frustración afectan el hígado y la vesícula biliar y además generan tensión muscular.
                    </p>
                </div>: null
            }
            {
                Terapia === "ConstelacionesFamiliares" ?
                <div>
                    <div className="navTerapias">
                        <button className="ButtonBack"  onClick={() => changeTerapia("Inicio")}><HiOutlineArrowSmallLeft className="Home"/> Atras</button>
                    </div>
                    <h1 className="Titulo">Constelaciones Familiares</h1>
                    <p className="Text"> 
                        Es un método desarrollado por el psicoterapeuta Bert Hellinger. Su objetivo es liberar a las personas de sus tensiones. Estas tensiones suelen venir de las generaciones pasadas y tienen sus raíces en los acontecimientos de la historia de la familia. Por ejemplo: guerra, violencia familiar o doméstica, fallecimiento prematuro de padres o de hijos, abortos, separaciones, violaciones… Todo ello puede manifestarse en las presentes generaciones como depresiones, psicosis, miedos, migrañas, cansancio crónico o problemas en las relaciones.
                    </p>
                    <p className="Text">
                        ¿Para qué sirve? Para tratar casi cualquier cosa, traumas de la infancia,  depresión, tristeza y melancolía, superar sucesos trágicos como las pérdidas o duelo, afrontar las enfermedades, para problemas de pareja, actual o relaciones del pasado, problemas de fertilidad o adopciones, problemas con los hijos (cualquier tipo), dificultades en las relaciones familiares o en las relaciones sociales.
                    </p>
                </div>: null
            }
        </div> 
    )
}