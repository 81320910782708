import "./Home.css"
import React, {useState, useEffect} from "react";
import logo from "../img/Logo.jpg"
import NavBar from "./NavBar";

export default function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
    setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
    window.removeEventListener('resize', handleResize);
    };
}, []);


  return (
    <div>
      <div className="bg-img"></div>
      <div className="Conteiner">
        <NavBar/>
        {
          windowWidth <= 650 ? <div className="App"> <img src={logo} alt="Sanar Terapias Complementarias" className="Logo"/> </div>
          : null        
        }

        <div className="ReikiDesc">
            <div className="TextReikiDesc">
              <h1>Que es Reiki?</h1>
            Reiki es una palabra japonesa que significa "energía vital universal". 
            Mantiene sus raíces en las tradiciones orientales.
            Es una terapia complementaria que tiene muchos beneficios: 
              <ul className="List">
                <li>Logra el equilibrio entre cuerpo, mente y espíritu.</li>
                <li>Activa nuestra propia energía centrada en los chacras, que son centros energéticos, generando así el alivio de dolencias físicas y mentales.</li>
                <li>Fomenta el equilibrio emocional.</li>
                <li>Aumenta la concentración y mejora la autoestima.</li>
              </ul>
              El Reiki no excluye ningún tipo de tratamiento médico, sino que es complementario y trabaja sobre nuestra energía y fortaleza interior.
            </div>
        </div>
        <div className="ReikiAngelesDesc">
            <div className="TextReikiDesc">
              <h1>Reiki con ángeles</h1>
            Los ángeles en sí no son religiosos por naturaleza. 
            Más bien son seres de luz abrazados por muchas religiones y personas espirituales por igual. 
            Ellos tienen muchas tareas y propósitos para ayudarte con la sanación. 
            Los ángeles son mensajeros del Espíritu que nos permiten una mayor comprensión y conciencia de los reinos superiores.
            Es una terapia complementaria que tiene muchos beneficios: 
              <ul className="List">
                <li>Conecta al paciente con su esencia más pura.</li>
                <li>Trabaja con la asistencia de ángeles y arcángeles para armonizar diferentes aspectos de la vida.  </li>
                <li>Ayudan a liberar dolores, miedos, inseguridades y resentimientos.</li>
                <li>Liberan emociones estancadas.</li>
                <li>Cambia creencias de patrones limitantes.</li>
                <li>Libera bloqueos.</li>
                <li>Fortalece la autoestima y confianza.</li>
                <li>Conecta con el YO superior.</li>
                <li>Purifica y armoniza el campo áurico y chacras.</li>
                <li>Me permite reconocer y aceptar la magia que hay en mi.</li>
              </ul>
              El reiki se puede considerar el mejor regalo para uno mismo, 
              ya que recibimos armonizaciones, 
              estamos dando a nuestro cuerpo un baño de luz y de sanación, 
              realizando el efecto dominó que se extiende a toda nuestra vida, 
              familia y amigos.
            </div>
        </div>
        <div className="ReikiChamanicoDesc">
            <div className="TextReikiDesc">
              <h1>Reiki chamánico</h1>
            Es la integración entre el Reiki Usui con elementos de sanación del Chamanismo. 
            esta unión desarrolla y potencia la energía Reiki, 
            conectándola con la sabiduría y la fuerza de los cuatro elementos: 
            aire, agua, tierra y fuego.
            Durante la sesión de reiki somos asistidos por estos elementos según vamos activando los chacras y la conexión con cada uno de ellos.
              <ul className="List">
                <li>Primer chacra conectamos con el elemento tierra, liberando hacia ella lo que queremos soltar, para luego enraizarnos, tomar su energía y satisfacer las necesidades básicas que tenemos como personas, el amor, la seguridad y la subsistencia.</li>
                <li>Segundo chacra conectamos con el elemento fuego, activamos la sexualidad, el placer y la alegría. El fuego transmuta situaciones emocionales.</li>
                <li>Tercer chacra conectamos con el elemento aire, activamos la fuerza de voluntad, la confianza en uno mismo, el equilibrio interno, la inspiración y la buena salud.</li>
                <li>Cuarto chacra conectamos con el elemento agua, surge la fluidez natural de las situaciones. Nos permitimos ser nosotros mismos. Activamos la capacidad de amar a los demás y de abrirnos a la vida.</li>
                <li>Quinto chacra conectamos con el elemento aire, activamos la capacidad de realizar una comunicación efectiva, expresar emociones, ideas y pensamientos.</li>
                <li>Sexto chacra conectamos con el elemento agua, activamos nuestros “sexto sentido”, seguimos el camino de la intuición, elegimos construir desde nuestra esencia, nuestro verdadero “ser” más allá del ego.</li>
                <li>Séptimo chacra conectamos en el elemento aire, activamos el centro de sabiduría y conexión espiritual, logramos comunicarnos con las energías del universo y con nuestro Yo superior. El sonido del chacra de la corona es el silencio.</li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  );
}